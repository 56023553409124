import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SaljaBostadPage = () => (
  <Layout>
    <SEO title="Sälja bostad" />
    <h1>Sälja bostad</h1>
    <h2>Din guide till en lyckad bostadsförsäljning</h2>
    <p>Du är besökare nummer 3!</p>
    <p>Fisken jobbar på att förbättra den här sidan.</p>
  </Layout>
)

export default SaljaBostadPage
